import React from "react";
import PropTypes from "prop-types";


const validateDisplayPageInPager = (key, currentPage, displayNum, totalPages) => {
    if(key > 1 && key < totalPages){
        if(displayNum > 0 && key >= (currentPage - displayNum) && key <= (currentPage + displayNum) ){
            return true;
        }
    }
    return false;
}
  
const PagerDots = () => {
    return (
        <>  
            <li className="mx-0">...</li>
        </>
    );
}

const PagerItem = ({
    children,
    validate,
}) => {
    return (
        <>  
            {validate &&
                <li className="mx-1">
                    {children }
                </li>
            }
        </>
    );
}
PagerItem.defaultProps = {
    validate: false,
}
PagerItem.propTypes = {
    children: PropTypes.node.isRequired,
    validate: PropTypes.bool,
}

const Pager = ({ 
    displayNum,
    nextLabel,
    previousLabel,
    linkClassName,
    linkClassNameActive,
    pageItemDefaultClassName,
    pageNavDefaultClassName,
    currentPage, 
    totalPages,
    pagerLinks,
}) => {
    const getLinkClassName = (key) => {
        let classes = `pager__link ${pageItemDefaultClassName} accent_on_hover`;
        if(key === currentPage){
            if(key >= (currentPage - displayNum) && key <= (currentPage + displayNum) ){
                classes += ` pager__link__current ${linkClassNameActive} active `;
            }else{
                classes += ` ${linkClassName} `;
            }
        }
        return classes;
    }
    
    return (
        <>
            {pagerLinks && pagerLinks.length > 1  && totalPages > 1 && 

                <nav className="pager" role="navigation" aria-label="Pagination Navigation">
                    <ul className="flex justify-between flex-no-wrap">
                        {/*  PREVIOUS LINK */}
                        <PagerItem validate={previousLabel && totalPages > 1 && currentPage > 1 ? true : false}>
                            <a href={pagerLinks[currentPage-1] || null} className={`pr-2 pager__link accent_on_hover ${pageNavDefaultClassName} ${linkClassName}`}>
                                {previousLabel}
                            </a>
                        </PagerItem>

                        {/*  FIRST NUMBER LINK */}
                        <PagerItem validate={true}>
                            <a href={currentPage > 1 ? pagerLinks[1] : null} className={`pager__link__first ${getLinkClassName(1)}`}>1</a>
                        </PagerItem>
   
                        {/* CONDITIONAL DOTS ELEM (AFTER FIRST) */}
                        {(currentPage - displayNum) > 2 && (
                            <PagerDots />
                        )}

                        {/*  NUMBER LINKS */}
                        {pagerLinks.map((elem, key) => (
                
                            <PagerItem validate={validateDisplayPageInPager(key, currentPage, displayNum, totalPages)} key={key}>
                                <a href={key !== currentPage ? elem : null} className={`${getLinkClassName(key)}`}>
                                    {key}
                                </a>
                            </PagerItem>
        
                        ))}

                        {/* CONDITIONAL DOTS ELEM (BEFORE LAST) */}
                        {(currentPage + displayNum) < (totalPages - 1) && (
                            <PagerDots />
                        )}

                        {/* LAST NUMBER LINK */}
                        <PagerItem validate={pagerLinks[totalPages] ? true : false}>
                            <a href={currentPage < totalPages ? pagerLinks[totalPages] : null} className={`pager__link__last ${getLinkClassName(totalPages)}`}>{totalPages}</a>
                        </PagerItem>

                        {/*  NEXT LINK */}
                        <PagerItem validate={nextLabel && currentPage < totalPages ? true : false}>
                            <a href={pagerLinks[currentPage+1] || null} className={`pl-2 pager__link accent_on_hover ${pageNavDefaultClassName} ${linkClassName}`}>
                                {nextLabel}
                            </a>
                        </PagerItem>

                    </ul>
                </nav>
            }
        </>
    )
}

Pager.defaultProps = {
    displayNum: 2,
    pagerLinks: [],
    linkClassName: `opacity-100 hover:opacity-50`,
    linkClassNameActive: `font-bold`,
    pageItemDefaultClassName: `px-2`,
    pageNavDefaultClassName: `font-bold uppercase underline`,
}


Pager.propTypes = {
    displayNum: PropTypes.number,
    nextLabel: PropTypes.string,
    previousLabel: PropTypes.string,
    linkClassName: PropTypes.string,
    linkClassNameActive: PropTypes.string,
    pageItemDefaultClassName: PropTypes.string,
    pageNavDefaultClassName: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    pagerLinks: PropTypes.array.isRequired,
}
  
export default Pager