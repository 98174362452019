import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Parser from "html-react-parser";
import Layout from "../components/layout"
import PageHero from "../components/images/pageHero"
import Pager from "../components/pager"
import { getPrettyDate, getStandardDate } from "../utils/dates"

const isDateDisplayed = true;

const getImagePreview = ( images ) => {
    let image = '';
    if(images[2] && images[2].mobile){
        image = images[2].mobile;
    }else if(images[1] && images[1].mobile){
        image = images[1].mobile;
    }else if(images[0] && images[0].mobile){
        image = images[0].mobile;
    }
    return image;
}

const textPreviewCharactersLength = 170; // define the length of characters for the text preview in a card.

const getPreviewText = ( textContent ) => {
    let text = textContent.replace( /(<([^>]+)>)/ig, '');
    if(text.length >= textPreviewCharactersLength){
        text = text.substr(0, (textPreviewCharactersLength - 1)) + '...';
    }
    return text;
}

const Archive = ({ pageContext }) => {
  const { t } = useTranslation(`default`)
  const { 
    title,
    post_accentColor, 
    post_summary,
    post_featureImage,
    post_scripts_styles,
    globalData, 
    posts,
    currentPage,
    totalPages,
    pagerLinks,
  } = pageContext;
  const accentColor = post_accentColor ? post_accentColor.replace('_accent', '') : 'no';

  return (
    <>
        <Layout { ...globalData } ressourceAssets={post_scripts_styles} >
            {(post_featureImage && post_featureImage[0]) && 
                <PageHero 
                    image={post_featureImage[0]}
                    imageMobile={post_featureImage[1] ? post_featureImage[1] : post_featureImage[0] }
                />
            }
            <div className={`page-container mx-auto my-8 title-color-container max-w-6xl relative block py-0 px-4 ${accentColor}_accent`} >
                <section className={`intro-block max-w-5xl m-auto px-8 mt-8`}>
                    <h1 className={`intro-block-title title text-center text-4xl lg:text-5xl uppercase`}>{title}</h1>
                    {post_summary && Parser(post_summary) }
                </section>
                <section className="relative p-12 px-0 mx-auto bg-white">
                    <div className="cards_container relative max-w-6xl pw-full p-0 m-auto">
                        {posts.map((postEntry, i) => (
                            <article className="card__news_preview relative block bg-white px-4 py-6 m-0 md:px-6 md:py-8" key={i}>
                                <a href={postEntry.url} rel="noreferrer" className="card__news_preview__content w-full h-full p-0 mx-auto max-w-sm" >
                                    {postEntry.post_featureImage && (
                                        <figure className={'card__news_preview__image relative w-full pt-full m-0'}>
                                            <img src={getImagePreview(postEntry.post_featureImage)} className={'w-full object-cover'} />
                                        </figure>
                                    )}
                                    {isDateDisplayed && 
                                        <time
                                            className="text-xs text-right block opacity-50 mx-2 mt-1 -mb-3"
                                            dateTime={getStandardDate(postEntry.postDate)}
                                        >
                                            {getPrettyDate(postEntry.postDate)}
                                        </time>
                                    }
                                    <h3 className="title text-xl font-bold mt-3 py-2">
                                        {postEntry.title}
                                    </h3>
                                    <p className="text text mb-4">
                                        {postEntry.post_summary && getPreviewText(postEntry.post_summary) }
                                    </p>
                                    {/* <button className={`ardene-btn brand-cta m-0 link_underline accent_underline card__news_preview__link`}>
                                        {t(`READ MORE`)}
                                    </button> */}
                                    <button className={`underline m-0 font-bold card__news_preview__link accent_on_hover`}>
                                        {t(`READ MORE`)}
                                    </button>
                                </a>
                            </article>
                        ))}
                    </div>
                </section>
                <div className="py-4 lg:pb-24 flex flex-row flex-no-wrap justify-center md:justify-start border-t-2 border-sand">
                    <Pager 
                        nextLabel={t(`next`)} 
                        previousLabel={t(`previous`)} 
                        currentPage={currentPage || 1} 
                        pagerLinks={pagerLinks} 
                        totalPages={totalPages || 1} 
                    />
                </div>
            </div>        
        </Layout>
    </>
  )
}

Archive.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default Archive